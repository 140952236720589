












import Vue from "vue";
import sourceWords from "./components/sourceWords.vue";
import mixes from "./components/mixes.vue";
import links from "./components/links.vue";

export default Vue.extend({
  name: "app",
  components: {
    sourceWords,
    mixes,
    links
  }
});
