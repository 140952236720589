













import Vue from "vue";
export default Vue.extend({
  name: "sourceWords",
  computed: {
    words: {
      get() {
        return this.$store.state.words.join(" ");
      },
      set(value) {
        return this.$store.commit("setWords", value.split(" "));
      }
    }
  },
  mounted: function() {
    this.$refs.words.focus();
  }
});
