












import Vue from "vue";
import orderBy from "lodash.orderby";
import uniq from "lodash.uniq";
export default Vue.extend({
  name: "mixes",
  computed: {
    orderedMixes: {
      get() {
        return orderBy(uniq(this.$store.state.mixes));
      }
    }
  }
});
